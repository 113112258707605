import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'

// Import actions
import { linkCreditCard } from '../../../factory'

class _CardForm extends Component {
	constructor(props) {
		super(props)
		this.handleChange = this.handleChange.bind(this)
	}

	// Handle when a user submit, create a stripe token
	componentDidUpdate(prevProps) {
		if (this.props.submitClicked && !prevProps.submitClicked) {
			this.props.stripe.createToken({
				name,
				type: 'card'
			}).then(res => {
				if (this.props.user) {
					this.props.linkCreditCard(this.props.user.id, res.token.id, res.token.card.last4, this.props.user.access_token)
				}
			})
		}
	}

	handleChange(change) {
		if (change.complete) {
			this.props.isComplete(true)
		} else {
			this.props.isComplete(false)
		}
	}

	createOptions(fontSize) {
		return {
			style: {
				base: {
					fontSize,
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, Menlo, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: '#9e2146',
				},
			},
		}
	}

	render() {
		return (
			<form className = "gray-form" >
				<CardElement onChange={this.handleChange} {...this.createOptions(this.props.fontSize)} />
			</form>
		)
	}
}

_CardForm.propTypes = {
	fontSize: PropTypes.string,
	stripe: PropTypes.object,
	user: PropTypes.object,
	linkCreditCard: PropTypes.func,
	isComplete: PropTypes.bool,
	submitClicked: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		selectedProj: state.donateNowState.selectedProj,
		user: state.authState.user,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkCreditCard: (userId, token, mask, accessToken) => dispatch(linkCreditCard(userId, token, mask, accessToken))
	}
}

_CardForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(_CardForm)

const CardForm = injectStripe(_CardForm)
export default CardForm
