import React from 'react'
import PropTypes from 'prop-types'

import {
	Flex,
	Paragraph,
} from 'reakit'

import {
	Button,
	Loader,
} from '../'

import  {
	FormContainer,
	ListField,
	ListItem,
} from './projectsFormStyles'

class ProjectsForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedProjects: this.props.selectedProjects || [],
		}

		this.selectOption = this.selectOption.bind(this)
		this.submitProjects = this.submitProjects.bind(this)
	}

	selectOption(project) {
		if (project) {
			var selectedProjects = this.state.selectedProjects || []

			if (this.state.selectedProjects && this.state.selectedProjects.indexOf(project.id) !== -1) {
				selectedProjects = this.state.selectedProjects.filter(proj => proj !== project.id)
			} else {
				selectedProjects = this.state.selectedProjects
				selectedProjects.push(project.id)
			}

			this.setState({
				selectedProjects: selectedProjects,
			})
		}
	}

	submitProjects() {
		if (this.state.selectedProjects.length) {
			this.props.onSelectProjects(this.state.selectedProjects)
		}
	}

	render() {
		return (
			<FormContainer>
				<Flex row marginBottom="2em" width="100%">
					<Flex column width="100%">
						{(this.props.errors && this.props.errors !== 'null') && <Paragraph>{this.props.errors}</Paragraph>}
						<Paragraph>FYI, You can pick more than 1</Paragraph>
						{this.props.projects ?
							<ListField>
								{(this.props.projects && this.props.projects.length > 0) && this.props.projects.map((item, i) =>
									<ListItem key={i} className={(this.state.selectedProjects && this.state.selectedProjects.indexOf(item.id) !== -1) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.name}</ListItem>
								)}
							</ListField> :
							<Loader />
						}
					</Flex>
				</Flex>
				<Flex row>
					<Flex column vAlign={'end'} row>
						<Button onClick={this.props.back} marginRight="1em">{'Back'}</Button>
						<Button onClick={this.props.skip} marginRight="1em">{'Skip'}</Button>
						<Button onClick={this.submitProjects} disabled={this.state.selectedProjects.length ? '' : 'disabled'}>{'Select projects'}</Button>
					</Flex>
				</Flex>
			</FormContainer>
		)
	}
}

ProjectsForm.propTypes = {
	projects: PropTypes.array,
	selectedProjects: PropTypes.array,
	onSelectProjects: PropTypes.func,
	skip: PropTypes.func,
	back: PropTypes.func,
	errors: PropTypes.string,
}

export default ProjectsForm
