import styled from 'styled-components'

import theme from '../../../theme/content'

export const Group = styled.div`
	border: 1px solid ${theme.colors.dustGray};
	border-radius: 5px;
	padding: 0.3em 1em;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'unset')};
	width: 100%;
	background: ${props => (props.bgColor ? props.bgColor : 'transparent')};
`
