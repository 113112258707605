import React from 'react'
import PropTypes from 'prop-types'

import routeValidate from '../../../util/routeValidate'

class PrivateRoute extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			didMount: false,
		}

		this.mountComponent = this.mountComponent.bind(this)
		this.validateSession = this.validateSession.bind(this)
	}

	componentDidMount() {
		this.validateSession()
	}

	mountComponent() {
		this.setState({
			didMount: true,
		})
	}

	validateSession() {
		routeValidate()

		this.mountComponent()
	}

	render() {
		return this.state.didMount && window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE ? <div>{this.props.children}</div> : <div/>
	}
}

PrivateRoute.propTypes = {
	children: PropTypes.any.isRequired
}

export default PrivateRoute
