import styled from 'styled-components'
import {Box, Button} from 'reakit'

import theme from '../../../theme/content'

export const BodyContainer = styled.main`
	background-color: ${props => props.bgColor ? props.bgColor : theme.colors.wildSand};
	background-image: ${props => props.bg && props.bg.image ? 'url(' + props.bg.image + ')' : 'none'};
	background-repeat: ${props => props.bg && props.bg.repeat ? props.bg.repeat : 'no-repeat'};
	background-size: ${props => props.bg && props.bg.size ? props.bg.size : 'auto'};
	background-position: ${props => props.bg && props.bg.position ? props.bg.position : 'center'};
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'calc(100% - 260px)')};
	padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)};
	padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 0)};
	width: 100%;
`

export const BodyHeader = styled(Box)`
	align-items: center;
	background-color: ${props => props.bgColor ? props.bgColor : theme.colors.mineShaft};
	display: flex;
	justify-content: space-between;
	padding: 0.5em 1em;
`

export const Logout = styled(Button)`
	background-color: transparent;
	color: ${theme.colors.mineShaft};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	width: 100%;
	text-align: left;
`

export const BodyContent = styled(Box)`
	align-items: start;
	max-height: calc(100vh - 60px);
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
`
export const BodyScrollable = styled.div`
	display: flex;
	flex-direction: column;
	min-height: min-content;
	padding: 1em 1em 0 1em;
	width: 100%;
`
