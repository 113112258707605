import styled from 'styled-components'

import {Link} from 'reakit'

import theme from '../../../theme/content'

export const Border = styled.div`
	background-image: url(https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/FooterPattern.png);
	height: 10px;
	max-width: calc(100% + 2em);
	width: 100%;
`

export const MenuOption = styled(Link)`
	color: ${theme.colors.mineShaft};
	font-size: 16px;
	transition: all 0.4s;

	&:not(:last-child) {
		margin-right: 1em;
	}

	&:hover {
		color: ${theme.colors.crusta};
		text-decoration: none;
	}
`
