import styled from 'styled-components'
import { Card, Paragraph } from 'reakit'
import theme from '../../../theme/content'

export const Container = styled(Card)`
	border-radius: 5px;
	padding: 1em;
	padding-bottom: 0em;
	max-width: calc(${props => props.totalCards ? (100 / props.totalCards) : 100}% - 1em);
	background-color: ${props => props.backgroundColor ? props.backgroundColor : 'white'};
	width: 100%;
	color: ${props => props.textColor ? props.textColor : 'inherit'}
`

export const Stat = styled(Paragraph)`
	font-family: ${theme.fonts.secondary};
	font-size: 1.5em;
	text-align: center;
	color: ${props => props.textColor ? props.textColor : theme.colors.crusta};
`
