import React from 'react'
import PropTypes from 'prop-types'

import {
	Flex,
	Block,
	Paragraph,
} from 'reakit'

import {
	Button,
	Input
} from '../../ui'

import {
	FormContainer,
} from './dailyDonateStyles'

class DailyDonate extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			customActive: false,
			amount: null,
		}

		this.onSelectDonation = this.onSelectDonation.bind(this)
		this.onSetCustomDonation = this.onSetCustomDonation.bind(this)
		this.submitDailyDonation = this.submitDailyDonation.bind(this)
	}

	onSelectDonation(e) {
		if (e.target) {
			this.setState({
				amount: e.target.value
			})

			if (Number(e.target.value) === 0) {
				this.setState({
					customActive: true,
				})
			} else {
				this.setState({
					customActive: false,
				})
			}
		}

		this.props.onSelectDonation(e)
	}

	onSetCustomDonation(e) {
		if (e.target) {
			this.setState({
				amount: e.target.value
			})
		}

		this.props.onSelectDonation(e)
	}

	submitDailyDonation() {
		this.props.submitDonation()
	}

	render() {
		return (
			<FormContainer>
				<Flex row marginBottom="2em" width="100%">
					<Flex column width="100%">
						{this.props.errors && <Paragraph>{this.props.errors}</Paragraph>}
						{this.props.fields && this.props.fields.map((fields, index) => {
							return fields && fields.map((field, i) => (
								<Block key={i} marginBottom="1em">
									<Input
										{...field}
										defaultValue={field.value}
										setValues={this.onSelectDonation}
										maxWidth={`calc(${field.maxWidth} - ${1 - 1 / fields.length}em)`}
										isChecked={(this.props.selectedDonation === Number(field.value))}
									/>
								</Block>
							))
						})}
						{this.state.customActive &&
							<Input
								label="Custom amount"
								field="default"
								type="number"
								name="custom-donation"
								placeholder="$"
								autoComplete="off"
								setValues={this.onSetCustomDonation}
							/>
						}
					</Flex>
				</Flex>
				<Flex row>
					<Flex column vAlign={'end'} row>
						<Button onClick={this.props.back} marginRight="1em">{'Back'}</Button>
						<Button onClick={this.props.skipt}>{'Skip'}</Button>
						<Button onClick={this.submitDailyDonation} marginRight="1em" maxWidth="250px">{'Submit Daily Donation'}</Button>
					</Flex>
				</Flex>
			</FormContainer>
		)
	}
}

DailyDonate.propTypes = {
	errors: PropTypes.string,
	selectedDonation: PropTypes.number,
	onSelectDonation: PropTypes.func,
	submitDonation: PropTypes.func,
	back: PropTypes.func,
	skip: PropTypes.func,
}

export default DailyDonate