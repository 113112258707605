// CheckoutForm.js
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {injectStripe} from 'react-stripe-elements'
import { Button, CheckBox, Link, Loader } from '../'
import CardSection from './CardSection'
import {Block, Paragraph, Flex} from 'reakit'
import theme from '../../../theme/content'
import { connect } from 'react-redux'
import ip from 'ip'

import {startSubscription} from '../../../factory'

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			term_agree: false,
			cancel_agree: false,
			user_ip: ip.address(),
			trial_agree: false,
			price_agree: false,
			errors: ''
		}

		this.handleCheck = this.handleCheck.bind(this)
	}


  handleSubmit = (ev) => {
  	// We don't want to let default form submission happen here, which would refresh the page.
  	ev.preventDefault()

  	const {term_agree, cancel_agree, trial_agree, price_agree} = this.state

  	if(!(term_agree && cancel_agree && trial_agree && price_agree)) {
  		this.setState({errors: 'Please agree to all 4 terms.'})
  		return false
  	}

  	const {stripe, user, organization, accessToken} = this.props
  	const legalState = Object.assign({}, this.state)
  	delete legalState.errors
  	legalState.flourish_admin_id = user.id
  	legalState.flourish_organization_id = organization.id


  	const data = {stripe, user, organization, access_token: accessToken, legal: legalState}

  	this.props.startSubscription(data)
  	return true

  	// However, this line of code will do the same thing:
  	//
  	// this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

  	// You can also use createSource to create Sources. See our Sources
  	// documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
  	//
  	// this.props.stripe.createSource({type: 'card', owner: {
  	//   name: 'Jenny Rosen'
  	// }});
  };

  handleCheck(e) {
  	this.setState({[e.target.name]: e.target.checked})
  }

  render() {
  	const {term_agree, cancel_agree, trial_agree, price_agree} = this.state
  	return (
  		<form onSubmit={this.handleSubmit}>
  			<CardSection />
  			<Block id="tos" style={{
  				marginTop: 15,
  				maxHeight: 250,
  				width: '100%',
  				overflow: 'scroll',
  				boxShadow: "0px 1px 0px 0px rgba(74, 74, 74, 0.3)"}} column>
  				<CheckBox
  					onChange={this.handleCheck}
  					name={'trial_agree'}
  					defaultSelected={trial_agree}
  					label={'I agree to a one (1) month trial period before automatically beginning a paid contract.'}
  				/>
  				<CheckBox
  					onChange={this.handleCheck}
  					name={'term_agree'}
  					defaultSelected={term_agree}
  					label={'I agree to automatically start a twelve (12) month contract term after the inital one (1) month trial period. This contract auto-renews.'}
  					/>
  				<CheckBox
    					onChange={this.handleCheck}
    					name={'price_agree'}
    					defaultSelected={price_agree}
    					label={'I agree to a monthly withdrawl of $35USD from the bank account above to auto-pay for twelve (12) months after the inital one (1) month trial period. You will be charged a rate of 5% on excess donations above $1500/mo.'}
    					/>
  				<CheckBox
    					onChange={this.handleCheck}
    					name={'cancel_agree'}
    					defaultSelected={cancel_agree}
    					label={'I acknowledge there is a fee if I cancel the contract before the last day.'}
      		/>
  			</Block>
  			{this.state.errors && <Paragraph style={{fontSize: '12px', marginTop: 10, color: 'red'}}>{this.state.errors}</Paragraph>}
  			<Flex row alignItems="center" justifyContent="left" marginTop={10}>
  				{this.props.pending ?	<Loader/> : <Button>Confirm</Button>}
  			</Flex>
  			<Paragraph style={{fontSize: '12px', marginTop: 10}}>
          By clicking the "Confirm" button below, you acknowledge you have read the <Link style={{display: 'inline'}} color={theme.colors.sunglow} href="flourishchange.com">terms of our service.</Link> If you believe you got this message as an error or have any questions, please call Zach at 817.823.9966.
  			</Paragraph>
  		</form>
  	)
  }
}

CheckoutForm.propTypes = {
	stripe: PropTypes.object,
	organization: PropTypes.object,
	pending: PropTypes.bool,
	user: PropTypes.object,
	accessToken: PropTypes.string,
	startSubscription: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		user: state.authState.user,
		pending: state.subscriptionState.pending,
		accessToken: state.authState.accessToken,
	}
}

const mapDispatchToProps = (dispatch) => {
	return{
		startSubscription: (data) => dispatch(startSubscription(data))
	}
}

CheckoutForm = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CheckoutForm)

export default injectStripe(CheckoutForm)
