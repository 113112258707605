/* eslint-disable */
import React, { Component } from "react"
import { Line, Bar, LinePath } from "@vx/shape"
import { Group } from "@vx/group"
import { withTooltip, Tooltip } from "@vx/tooltip"
import { localPoint } from "@vx/event"
import { scaleTime, scaleLinear } from "@vx/scale"
import { extent, max, bisector, min } from "d3-array"
import { timeFormat } from "d3-time-format"
import { AxisLeft, AxisBottom } from "@vx/axis"
import theme from '../../../theme/content'
import { Heading } from "reakit"


const width = typeof window !== 'undefined' && window.innerWidth - 350
const height = 200

const formatDate = timeFormat("%b %d, '%y")
const xSelector = d => new Date(d.date)
const ySelector = d => d.sum

const bisectDate = bisector(xSelector).left
const margin = {
  left: 20,
  right: 10,
  top: 10,
  bottom: 25
}

class App extends Component {
  state = {
  	data: null,
  };

  async componentDidMount() {
  	const data = await this.props.data

  	this.setState({
  		data
  	})
  }

  handleTooltip = ({ event, data, xSelector, xScale, yScale }) => {
  	const { showTooltip } = this.props
  	const { x } = localPoint(event)
  	const x0 = xScale.invert(x - margin.left)
  	const index = bisectDate(data, x0, 1)
  	const d0 = data[index - 1]
  	const d1 = data[index]
  	let d = d0
  	if (d1 && d1.date) {
  		d = x0 - xSelector(d0) > xSelector(d1) - x0 ? d1 : d0
  	}
  	showTooltip({
  		tooltipData: d,
  		tooltipLeft: xScale(xSelector(d)),
  		tooltipTop: yScale(ySelector(d)),
  	})
  };

  render() {
  	const { data } = this.props
  	const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft } = this.props

  	if (!data) return null

		const padding=30
		const xMax = width - margin.left - margin.right
		const yMax = height - margin.top - margin.bottom

  	const xScale = scaleTime({
  		range: [margin.left, xMax - margin.right],
  		domain: extent(data, xSelector),
  	})

  	const dataMax = max(data, ySelector)
		const dataMin = min(data, ySelector)
  	const yScale = scaleLinear({
  		range: [yMax, padding],
  		domain: [dataMin - dataMin / 3, dataMax + dataMax / 3],
  	})



  	return (
  		<div>
			<Heading as="h4" margin="0" textAlign="center">Donation History</Heading>
  			<svg width={'100%'} height={height}>
  				<rect
						x={0}
						y={0}
						width={width}
						height={height}
						fill="transparent"
						margin={'0 auto'}
					/>
				<Group top={margin.top} left={margin.left}>
					<AxisLeft
						scale={yScale}
            			numTicks={8}
						tickFormat={d => `$${d}`}
						stroke={'transparent'}
						tickStroke={'transparent'}
						tickLabelProps = {() =>
							({ textAnchor: 'middle',
								fontFamily: theme.fonts.primary,
								fontSize: 10,
								fill: theme.colors.mineShaft })
						}
					/>
					<AxisBottom
						top={yMax}
						scale={xScale}
						tickLength={3}
						stroke={theme.colors.mineShaft}
						tickLabelProps = {() =>
							({ textAnchor: 'middle',
								fontFamily: theme.fonts.primary,
								fontSize: 10,
								fill: theme.colors.mineShaft,
                				dy: '0.25em'
							 	})
						} />
  				<LinePath
  					data={data}
  					xScale={xScale}
  					yScale={yScale}
  					x={xSelector}
  					y={ySelector}
  					strokeWidth={3}
  					stroke={theme.colors.crusta}
  					strokeLinecap="round"
  					fill="transparent"
  				/>
  				<Bar
  					x={margin.left}
  					y={0}
  					width={xMax}
  					height={yMax}
  					fill="transparent"
  					data={data}
  					onMouseMove={data => event =>
  						this.handleTooltip({
  							event,
  							data,
  							xSelector,
  							xScale,
  							yScale,
  						})}
  					onMouseLeave={data => event => hideTooltip()}
  					onTouchEnd={data => event => hideTooltip()}
  					onTouchMove={data => event =>
  						this.handleTooltip({
  							event,
  							data,
  							xSelector,
  							xScale,
  							yScale,
  						})}
  				/>
  				{tooltipData && (
  					<g>
  						<Line
  							from={{ x: tooltipLeft, y: 0 }}
  							to={{ x: tooltipLeft, y: yMax }}
  							stroke={theme.colors.mineShaft}
  							strokeWidth={4}
  							style={{ pointerEvents: "none" }}
  							strokeDasharray="4,6"
  						/>
  						<circle
  							cx={tooltipLeft}
  							cy={tooltipTop}
  							r={4}
  							fill={theme.colors.mineShaft}
  							stroke="white"
  							strokeWidth={2}
  							style={{ pointerEvents: "none" }}
  						/>
  					</g>
  				)}
				</Group>
  			</svg>
  			{tooltipData && (
  				<div>
  					<Tooltip
  						top={tooltipTop - 12}
  						left={tooltipLeft + 12}
  						style={{
  							backgroundColor: theme.colors.mineShaft,
  							color: "#FFF",
  						}}
  					>
  						{`$${ySelector(tooltipData)}`}
  					</Tooltip>
  					<Tooltip
  						top={25}
  						left={tooltipLeft + margin.left}
  						style={{
  							transform: "translateX(-50%)",
  						}}
  					>
  						{formatDate(xSelector(tooltipData))}
  					</Tooltip>
  				</div>
  			)}
  		</div>
  	)
  }
}

export default withTooltip(App)
