import React from 'react'
import PropTypes from 'prop-types'

import {Button} from '../'

class ExampleCustomInput extends React.Component {
	render() {
		return (
			<Button
				width={150}
				onClick={this.props.onClick}>
				{this.props.value}
			</Button>
		)
	}
}

ExampleCustomInput.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.string
}

export default ExampleCustomInput
