import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  	Heading, Paragraph, Flex
} from 'reakit'

import {
	Container,
	Stat
} from './statCardStyles'

import {
	Loader
} from '../'

import currencyFormat from '../../../util/currencyFormat'

class StatCard extends Component {
	constructor(props) {
		super(props)

		this.renderValue = this.renderValue.bind(this)
	}

	renderValue() {
		if (this.props.error) {
			return (
				<Stat {...this.props} margin="1em">error</Stat>
			)
		}
		return (
			<Stat {...this.props} margin="1em">{this.props.type === 'money' ? currencyFormat(this.props.stat) : this.props.stat}</Stat>
		)
	}
	render() {
		return(
			<Container {...this.props} totalCards={this.props.totalCards}>
				<Heading style={{...this.props.titleStyle}} as="h4" margin="0" textAlign="center">{this.props.title}</Heading>
				{this.props.loading && !this.props.error ?
					<Flex row justifyContent="center" alignContent="center"><Loader/></Flex> :
					this.renderValue()
				}
				<Paragraph style={{...this.props.subtitleStyle}} height="2em" textAlign="center">{this.props.description}</Paragraph>
			</Container>
		)
	}
}

StatCard.propTypes = {
	totalCards: PropTypes.number,
	title: PropTypes.string,
	stat: PropTypes.number,
	description: PropTypes.string,
	type: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.bool,
	subtitleStyle: PropTypes.object,
	titleStyle: PropTypes.object,
	statStyle: PropTypes.object
}

export default StatCard
