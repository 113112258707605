// CardSection.js
import React from 'react'
import {CardElement} from 'react-stripe-elements'
import {Paragraph} from 'reakit'

class CardSection extends React.Component {
	render() {
		return (
			<label>
				<Paragraph>To continue to to your free trial, please enter your card details.</Paragraph>
				<CardElement style={{base: {fontSize: '18px'}}} />
			</label>
		)
	}
}

export default CardSection
