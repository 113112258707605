import React from 'react'

import {Cotnainer, Text} from './breadcrumbsStyles'

import theme from '../../../theme/content'

import {
	getUserInfo,
} from '../../../util/auth'

class Breadcrumbs extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currentPath: '',
		}

		this.updateLocation = this.updateLocation.bind(this)
	}

	componentDidMount() {
		this.updateLocation()
	}

	updateLocation() {
		this.setState({
			currentPath: location.pathname,
		})
	}

	render() {
		const profile = getUserInfo()

		return (
			<Cotnainer row>
				{theme.body.header.breadcrumbs &&
					theme.body.header.breadcrumbs.map((item, index) => {
						return (
							this.state.currentPath.indexOf(item.path) !== -1 && (
								<Text
									as="a"
									href={item.path === '/' ? (profile.userRole === 1 ? '/admin' : item.path) : item.path}
									marginBottom="0"
									key={index}
									color={theme.colors.mineShaft}
								>
									{item.title}
								</Text>
							)
						)
					})}
			</Cotnainer>
		)
	}
}

export default Breadcrumbs
