import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {Provider as ReakitProvider} from 'reakit'

import './layout.css'

import themeComponents from '../theme/Components'

const Layout = ({children}) => {
	return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={data => (
				<div>
					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{name: 'description', content: 'Sample'},
							{name: 'keywords', content: 'sample, something'},
						]}
					>
						<html lang="en" />
						<link
							rel="stylesheet"
							href="https://use.fontawesome.com/releases/v5.4.1/css/all.css"
							integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz"
							crossOrigin="anonymous"
						/>
					</Helmet>
					<ReakitProvider theme={themeComponents}>
						<div>
							{children}
						</div>
					</ReakitProvider>
				</div>
			)}
		/>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
