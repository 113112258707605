import styled from 'styled-components'
import {Button as Btn} from 'reakit'

import theme from '../../../theme/content'

const Button = styled(Btn).attrs({
	disabled: props => (props.disabledBtn ? props.disabledBtn : ''),
})`
	background-color: ${theme.colors.sunglow};
	border-radius: 50px;
	color: ${theme.colors.mineShaft};
	max-width: ${props => props.maxWidth ? props.maxWidth : '200px'};
	width: 100%;

	&:hover {
		background-color: ${theme.colors.buttonsHovered};
	}

	&:disabled {
		background-color: rgb(247, 119, 47);
	}
`

export default Button
