import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Image, Heading, Divider, Block, Paragraph} from 'reakit'
import { css } from "emotion"

import {SidebarContainer, Option, SubOption} from './sidebarStyles'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import theme from '../../../theme/content'

class Sidebar extends React.Component {
	constructor(props) {
		super(props)

		this.content = this.props.content
	}

	render() {
		const {onboarding} = this.props
		return (
			<SidebarContainer column>
				<Block>
					{this.props.organization && this.props.organization.title ? <Heading style={{width: '100%', fontSize: '24px'}}>
						{this.props.organization.title}
					</Heading> :
						<Image src={theme.images.logo} width={230} />
					}
					<Divider />
					{this.content && this.content.options && this.content.options.map((item, index) => (
						<div key={index}>
							<Option as={'a'} href={item.link} key={index}>
								<i className={item.faIcon} />
								{item.text}
							</Option>
							{item.subItems && item.subItems.map((subItem, k) => {
								if (typeof window !== 'undefined' && window.location.href.includes(item.link)) {
									return (
										<SubOption as={'a'} href={`${item.link}${subItem.link}`} key={k}>
											<i className={'fas fa-circle'} />
											{subItem.text}
										</SubOption>
									)
								}
								return null
							})

							}

						</div>
					))}
				</Block>
				<Block>
					{/*
					<Block style={{marginBottom: 20, borderRadius: 15, borderColor: 'black', borderWidth: 2}}>
						<Option as={'a'} href={'/admin/checklist/'} style={{fontSize: 18, fontWeight: 400, marginBottom: 5}}>ONBOARDING TO-DO'S</Option>
						<Block onClick={()=> {window.location.href = "/admin/checklist/"; return null}}
							className={css({marginRight: 100, marginBottom: 20, '&:hover': { background: 'rgba(0,0,0,.15)', cursor: 'pointer', borderRadius: 70 }})}
						>
							<CircularProgressbar styles={{
								path: {
									// Path color
									stroke: `rgba(247, 119, 47)`,
								},
								text: {
						      // Text color
						      fill: `#ffffff`,
						      // Text size
									fontFamily: 'work sans',
									fontSize: '24px'
						    },
								trail: {
						      // Trail color
						      stroke: '#ffffff',
						    },
							}}
							value={onboarding.currentCompleteCount / onboarding.totalTasks * 100} text={`${onboarding.currentCompleteCount}/${onboarding.totalTasks}`} />
						</Block>
					</Block>*/}
					<Image src={theme.images.logo} width={160} style={{margin: 'auto'}}/>
				</Block>
			</SidebarContainer>
		)
	}
}

Sidebar.propTypes = {
	content: PropTypes.object,
	organization: PropTypes.object,
	onboarding: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		onboarding: state.onboardingState
	}
}

export default connect(
	mapStateToProps,
	null,
)(Sidebar)
