import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	${props => props.column && 'flex-direction: column;'} margin: 0 auto;
	max-height: ${props => (props.maxHeight ? props.maxHeight : 'unset')};
	max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
	overflow: hidden;
	padding: 0;
	width: 100%;
	background-image: ${props => props.bg && props.bg.image ? 'url(' + props.bg.image + ')' : 'none'};
  background-repeat: ${props => props.bg && props.bg.repeat ? props.bg.repeat : 'no-repeat'};
  background-size: ${props => props.bg && props.bg.size ? props.bg.size : 'auto'};
  background-position: ${props => props.bg && props.bg.position ? props.bg.position : 'center'};
  padding: ${props => props.padding ? props.padding : '0 0 0 0'};
  padding-top: ${props => props.paddingTop && props.paddingTop};
  padding-bottom: ${props => props.paddingBottom && props.paddingBottom};
`;

export default Container;
