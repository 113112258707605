/* eslint-disable */
import React from 'react'


import {
	Bar,
	BarContainer,
	Logo,
	Menu,
	MenuOption,
	MenuLink,
} from './mobileHeaderStyles'

import {Heading, Paragraph} from 'reakit'
import {Container, Modal} from '../../layouts'


import theme from '../../../theme/content'

class TopBar extends React.Component {
	constructor(props) {
		super(props)

		this.linking = this.linking.bind(this)
	}

	linking(e) {
		e.preventDefault()

		const link = e.target.getAttribute('href')
		window.location.href = link
	}

	render() {
		return (
			<Bar>
				<Container>
					<BarContainer>
						{this.props.backLink && (
							<a
								href={this.props.backLink}
								style={{color: 'inherit', marginTop: 15}}
							>
								<i
									className="fas fa-chevron-left"
									style={{
										backgroundColor: 'transparent',
										width: 25,
										height: 25,
									}}
								/>
							</a>
						)}
						{this.props.backClick && (
							<i
								onClick={this.props.backClick}
								className="fas fa-chevron-left"
								style={{
									backgroundColor: 'transparent',
									width: 25,
									height: 25,
									marginTop: 18,
								}}
							/>
						)}
						{this.props.noBack && (
							<div
								style={{
									backgroundColor: 'transparent',
									width: 25,
									height: 25,
									marginTop: 18,
								}}
							/>
						)}
						<Heading as="h4">{this.props.title}</Heading>
						<Modal
							button={{
								backgroundColor: 'transparent',
								text: '  ?  ',
								fontWeight: 'bold',
								marginTop: '10px'
							}}
							containterStyle={{width: '90%'}}
						>
							<Heading as="h4">{`Where'd  we go?`}</Heading>
							<Paragraph>
							Due to the guidelines of the Apple App Store, we {`can't `}{' '}
							process donations from within our app. So we had to come
							here.{` Don't worry, we'll be back in our app soon. `}
							</Paragraph>
						</Modal>
					</BarContainer>
				</Container>
			</Bar>
		)
	}
}

export default TopBar
