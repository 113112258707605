import styled from 'styled-components'

import theme from '../../../theme/content'

export const Bar = styled.div`
	background-color: ${theme.colors.sunglow};
	left: 0;
	position: fixed;
	top: 0;
	z-index: 9;
	width: 100%;
`

export const BarContainer = styled.div`
	align-items: center;
	display: flex;
	height: 72px;
	justify-content: space-between;
	width: 100%;
	padding: 2em 4.75em;
`

export const Logo = styled.img.attrs({
	src: theme.images.logo,
})`
	margin: 0;
	max-width: 168px;
	width: 100%;
`

export const Menu = styled.ul`
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	color: ${theme.colors.black};
`

export const MenuOption = styled.li`
	font-family: ${theme.fonts.primary};
	font-size: 16px;
	margin-bottom: 0;
	color: ${theme.colors.black};

	&:not(:last-child) {
		margin-right: 2.5em;
		color: ${theme.colors.black};
	}

	&:last-child {
		color: ${theme.fonts.crusta};
	}
`

export const MenuLink = styled.a`
	color: ${theme.colors.black};
	text-decoration: none;

	&:hover,
	&:focus {
		color: ${theme.colors.menuLink};
	}
`
