import React from 'react';
import PropTypes from 'prop-types';

import {Table as Tbl} from 'reakit';

import {Link} from './tableStyles';

class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Tbl margin="0">
        <thead>
          <tr>
            {this.props.thead &&
							this.props.thead.map((cell, index) => (
							  <th key={index} width={cell.width}>
							    {cell.title}
							  </th>
							))}
          </tr>
        </thead>
        <tbody>
          {this.props.tbody &&
						this.props.tbody.map((row, index) => (
						  <tr key={index}>
						    {row &&
									row.map((cell, i) => (
									  <td key={i}>
									    {this.props.linked && i === 1 ? (
									      <Link
									        href={
									          this.props.urlBase +
														'/' +
														row[0]
									        }
									        title={cell}
									      >
									        {cell}
									      </Link>
									    ) : (
									      cell
									    )}
									  </td>
									))}
						  </tr>
						))}
        </tbody>
      </Tbl>
    );
  }
}

Table.propTypes = {
  thead: PropTypes.array,
  tbody: PropTypes.array,
  linked: PropTypes.bool,
  urlBase: PropTypes.string,
};

export default Table;
