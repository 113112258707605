import styled from 'styled-components'

import theme from '../../../theme/content'

export const FormContainer = styled.div`
	overflow: hidden;
	padding-top: 1.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
`

export const SearchField = styled.div`
	align-items: center;
	background-color: ${theme.colors.wildSand};
	border-radius: 4px;
	display: flex;
	height: 48px;
	margin-bottom: 1em;
	padding: 0.5em;
	width: 100%;
`

export const SearchIcon = styled.i`
	color: ${theme.colors.dustyGray};
	font-size: 20px;
	height: 20px;
`

export const SearchInput = styled.input.attrs({
	type: 'search'
})`
	appearance: none;
	background: transparent;
	border: none;
	font-family: ${theme.fonts.primary};
	padding: 0 0.5em;
	outline: none;
	width: 100%;
`

export const ListField = styled.ul`
	list-style: none;
	height: 30vh;
	margin: 0;
	max-height: 30vh;
	overflow-x: scroll;
	padding: 0;
`

export const ListItem = styled.li`
	border-bottom: 1px solid ${theme.colors.silver};
	cursor: pointer;
	font-family: ${theme.fonts.primary};
	font-size: 21px;
	margin-bottom: 0;
	padding: 0.5em 0;
	transition: all 0.4s;
	width: 100%;

	&.selected {
		background-color: ${theme.colors.lightGray};
	}

	&:hover,
	&:focus {
		background-color: ${theme.colors.lightGray};
	}
`