import styled from 'styled-components'

import theme from '../../../theme/content'

export const FormContainer = styled.div`
	margin: 0 !important;
	overflow: hidden;
	width: 100%;
`

export const ListField = styled.ul`
	list-style: none;
	height: 30vh;
	margin: 0;
	max-height: 30vh;
	overflow-x: scroll;
	padding: 0;
	width: 100%;
`

export const ListItem = styled.li`
	border-bottom: 1px solid ${theme.colors.silver};
	cursor: pointer;
	font-family: ${theme.fonts.primary};
	font-size: 21px;
	margin-bottom: 0;
	padding: 0.5em 0;
	transition: all 0.4s;
	width: 100%;

	&.selected {
		background-color: ${theme.colors.lightGray};
	}

	&:hover,
	&:focus {
		background-color: ${theme.colors.lightGray};
	}
`