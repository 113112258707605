import React from 'react'
import PropTypes from 'prop-types'

import {
	Label, Input as Field, Flex, Paragraph,
} from 'reakit'

import { Group } from './inputStyles'

class Input extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			client: null
		}

		this.handleChange = this.handleChange.bind(this)
	}

	componentDidMount() {
		this.createClient()
	}

	createClient = () => {
		// eslint-disable-next-line
		this.setState({client: filestack.init(process.env.filestackKey)})
	}

	handleChange(e) {
		this.props.setValues(e)
	}

	onSuccess = (result) => {
		const event = { target: {
			name: 'image_url',
			value: result.url
		} }
		this.handleChange(event)
	}

	onError = (error) => {
		this.setState({ error })
	}

	render() {
		return (
			<Group maxWidth={this.props.maxWidth}>
				<Label>{this.props.label}</Label>
				{this.props.field === 'default' && (
					<Field
						type={this.props.type}
						name={this.props.name}
						placeholder={this.props.placeholder}
						defaultValue={this.props.defaultValue}
						value={this.props.value}
						autoComplete="off"
						onChange={this.handleChange}
						onKeyUp={this.handleChange}
						style={this.props.style}
						maxLength={this.props.maxLength}
					/>)}
				{this.props.field === 'textarea' && (
					<div>
						<Field
							as="textarea"
							name={this.props.name}
							placeholder={this.props.placeholder}
							cols={this.props.columns}
							rows={this.props.rows}
							value={this.props.value}
							disabled={this.props.disabled}
							autoComplete="off"
							onChange={this.handleChange}
							defaultValue={this.props.defaultValue}
							value={this.props.value}
							style={this.props.style}
							maxLength={this.props.maxLength}
						/>
						{this.props.maxLength && <Paragraph style={{width: '100%', textAlign: 'right', fontSize: 12}}>{this.props.length}/{this.props.maxLength}</Paragraph>}
					</div>)}
				{this.props.field === 'checkbox' && (
					<Flex row width="100%">
						<Field
							type={this.props.type}
							name={this.props.name}
							placeholder={this.props.placeholder}
							autoComplete="off"
							onChange={this.handleChange}
							defaultValue={this.props.defaultValue}
							style={this.props.style}
							{...this.props.isChecked ? {defaultChecked: 'checked'} : ''}
						/>
						<Paragraph
							fontSize="0.75em"
							lineHeight="1em"
							margin="0 0 0 1em"
							dangerouslySetInnerHTML={{ __html: this.props.text }}
						/>
					</Flex>
				)}
				{this.props.field === 'select' && (
					<Field
						as={this.props.field}
						name={this.props.name}
						autoComplete="off"
						onChange={this.handleChange}
						selectedValue={this.props.defaultValue}
						value={this.props.defaultValue}
					>
						{this.props.options && this.props.options.map((item, ii) => {
							const valueSelector = this.props.valueSelector || 'value'
							const labelSelector = this.props.labelSelector || 'text'
							return (<option key={ii} value={item[valueSelector]}>{item[labelSelector]}</option>)
						})}
					</Field>
				)}
				{this.props.field === 'file' && (
					<input
						type="file"
						accept="image/*"
						onChange={
							(e) => this.state.client.upload(e.target.files[0]).then(this.onSuccess)
								.catch(this.onError)
						}
					/>
				)}
			</Group>
		)
	}
}

Input.propTypes = {
	label: PropTypes.string,
	field: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	maxWidth: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	setValues: PropTypes.func,
	defaultValue: PropTypes.any,
	isChecked: PropTypes.bool,
	style: PropTypes.object,
	text: PropTypes.string,
	columns: PropTypes.string,
	rows: PropTypes.string,
	valueSelector: PropTypes.string,
	labelSelector: PropTypes.string,
	disabled: PropTypes.bool,
	maxLength: PropTypes.number,
	length: PropTypes.number,
	value: PropTypes.string
}

export default Input
