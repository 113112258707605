import styled from 'styled-components'
import {Flex, Paragraph} from 'reakit'

import theme from '../../../theme/content'

export const Cotnainer = styled(Flex)``

export const Text = styled(Paragraph)`
	color: ${theme.colors.white};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	margin-right: 0.5em;
	text-transform: uppercase;
	text-decoration: none;
`
