import styled from 'styled-components'
import {Flex, Button} from 'reakit'

import theme from '../../../theme/content'

export const SidebarContainer = styled(Flex)`
	align-items: center;
	background-color: ${theme.colors.yellow};
	display: flex;
	height: 100vh;
	justify-content: space-between;
	max-height: 100%;
	max-width: 260px;
	padding: 1em;
	width: 100%;
`

export const Option = styled(Button)`
	justify-content: left;
	background-color: transparent;
	border-radius: 3.5em;
	color: ${theme.colors.white};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	height: 3.5em;
	margin-bottom: 1em;
	text-transform: uppercase;
	width: 100%;

	i {
		font-size: 18px;
		margin-right: 0.5em !important;
	}

	&:hover {
		color: ${theme.colors.white};
	}
`

export const SubOption = styled(Button)`
	justify-content: left;
	background-color: transparent;
	border-radius: 3.5em;
	color: ${theme.colors.white};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	height: 2em;
	margin-left: 20px;
	margin-bottom: 0.5em;
	text-transform: uppercase;
	width: 90%;

	i {
		font-size: 18px;
		margin-right: 0.5em !important;
	}

	&:hover {
		color: ${theme.colors.white};
	}
`
