/**
 * Convert a number to currency values
 * @param {Number} num
 * @param {Number} fraction
 * @returns {String}
 */
const currency = (num, fraction = 2) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: fraction
	});

	return formatter.format(num);
};

export default currency
