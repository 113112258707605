import React from 'react'

import {Box, Flex, Paragraph, Image, Link} from 'reakit'

import {Border, MenuOption} from './footerStyles'

import theme from '../../../theme/content'

class Footer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Box width="100%">
				<Flex marginBottom="1em" padding="0 2em" row justifyContent="flex-end" >
					{theme.footer.menu &&
						theme.footer.menu.map((item, index) => (
							<MenuOption key={index} href={`https://flourishchange.com${item.link}`}>
								{item.text}
							</MenuOption>
						))}
				</Flex>
				<Flex padding="0 1em" row>
					<Flex column width="50%">
						<Paragraph
							fontSize="14px"
							marginBottom="0"
							dangerouslySetInnerHTML={{
								__html: theme.footer.copyRight,
							}}
						/>
						<Paragraph fontSize="14px" marginBottom="1em">
							{theme.footer.madeBy}
						</Paragraph>
					</Flex>
					<Flex row width="50%" justifyContent="flex-end" paddingRight="1em">
						<Link href={theme.footer.appleStore.link}>
							<Image
								src={theme.footer.appleStore.image}
								height="48px"
								marginRight="1em"
								maxHeight="48px"
							/>
						</Link>
						<Link href={theme.footer.playStore.link}>
							<Image
								src={theme.footer.playStore.image}
								height="48px"
								maxHeight="48px"
							/>
						</Link>
					</Flex>
				</Flex>
				<Border />
			</Box>
		)
	}
}

export default Footer
