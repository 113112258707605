import styled, {keyframes} from 'styled-components'
import image from '../../../images/bigLogo.png'

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

// Here we create a component that will rotate everything we pass in over two seconds
const Loader = styled.div`
  background-image: url(${image});
  animation: ${rotate} 2s linear infinite;
  width: ${props => props.width ? props.width : '2em'};
  height: ${props => props.height ? props.height : '2em'};
  margin: auto;
  background-size: contain;
`

export default Loader
