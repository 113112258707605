import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { navigate } from '@reach/router'

import {
	InlineBlock,
	Button,
	Popover,
	Flex
} from 'reakit'

import {
	Breadcrumbs,
	DateRange,
} from '../../ui'

import {
	BodyContainer,
	BodyHeader,
	BodyContent,
	BodyScrollable,
	Logout,
} from './bodyStyles'

import {
	logout,
} from '../../../factory'

import {
	cleanSession
} from '../../../util/auth'

import theme from '../../../theme/content'

class Body extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			pathSegments: 0,
		}
	}

	componentWillMount() {
		this.getPath()
	}

	getPath() {
		const countSegments =  typeof window !== 'undefined' && (window.location.pathname.split('/').length - 1) - (window.location.pathname[location.pathname.length - 1] === '/' ? 1 : 0)
		const pathName = typeof window !== 'undefined' && window.location.pathname.replace(/\/$/, '')
		this.setState({
			pathSegments: countSegments,
			publicPath: theme.publicPaths.includes(pathName)
		})
	}

	render() {
		return (
			<BodyContainer {...this.props}>
				<BodyHeader bgColor={'white'}>
					<Breadcrumbs />
					<Flex row>
						{this.props.showDate && <DateRange
							backgroundColor={'white'}
							textColor={theme.colors.mineShaft}/>}
						<Popover.Container>
							{popover => (
								<InlineBlock relative>
									<Button
										as={Popover.Toggle}
										backgroundColor={theme.colors.sunglow}
										{...popover}
									>
										<i className="far fa-user fa-2x" />
									</Button>
									<Popover
										fade
										slide
										expand
										hideOnClickOutside
										{...popover}
									>
										<Popover.Arrow />
										<Logout as="a" href={theme.body.header.organization.link}>
											{theme.body.header.organization.text}
										</Logout>
										{/* <Logout as="a" href={theme.body.header.onboarding.link}>
											{theme.body.header.onboarding.text}
										</Logout>*/}
										<Logout onClick={this.props.onLogout}>
											{theme.body.header.logout.text}
										</Logout>
									</Popover>
								</InlineBlock>
							)}
						</Popover.Container>
					</Flex>
				</BodyHeader>
				{/* this.props.userRole === 1 &&
					<StripeCheckout paid={!this.props.is_signed}/>
				*/}
				<BodyContent>
					<BodyScrollable>{this.props.children}</BodyScrollable>
				</BodyContent>
			</BodyContainer>
		)
	}
}

Body.propTypes = {
	children: PropTypes.any,
	onLogout: PropTypes.func,
	maxWidth: PropTypes.string,
	showDate: PropTypes.bool
}

const mapStateToProps = (state) => {
	const adminOrg = state.adminState.organization
	const authOrg = state.authState.adminOrg
	return {
		userRole: state.authState.role,
		is_signed: (adminOrg && adminOrg.is_signed) ||  (authOrg && authOrg.is_signed)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => {
			dispatch(logout())
			cleanSession()
			navigate('/login')
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Body)
