import React from 'react'

import {Paragraph, Flex, Heading} from 'reakit'

import {
	Container,
	FooterMenu,
	FooterBlock
} from '..'

import {
	Link,
	Image,
	SocialBtn
} from '../../ui'

import theme from '../../../theme/content'

class HomeFooter extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div>
				<Container padding="2em 3em 2em 3em">
					<Flex marginBottom="1em" row width="100%">
						<Flex width="35%" smallMaxWidth="22%" column>
							<Image
								src={theme.layoutsContent.webFooter.content.footerLogo}
								marginBottom={'1em'}
								marginRight={'auto'}
								maxWidth={'168px'}
								height={"auto"}
								width={'auto'}
							/>
							<Paragraph
								fontSize={'14px'}
								lineHeight={'1.3em'}
								width="80%"
								dangerouslySetInnerHTML={{
									__html: theme.layoutsContent.webFooter.content.footerDescription,
								}}
							/>
						</Flex>
						<Flex width="25%" smallMaxWidth="22%" column>
							<FooterMenu data={theme.layoutsContent.webFooter.content.navigation} />
							<FooterMenu data={theme.layoutsContent.webFooter.content.legal} />
						</Flex>
						<Flex width="20%" smallMaxWidth={'30%'} column>
							<FooterBlock data={theme.layoutsContent.webFooter.content.contact} />
						</Flex>
						<Flex
							width="20%"
							smallMaxWidth={'23%'}
							column
							alignItems={'center'}
						>
							<Heading fontSize={'24px'} marginBottom={'0.8em'}>
								{theme.layoutsContent.webFooter.content.social.title}
							</Heading>
							<Flex row paddingBottom="1em">
								<Flex row vAlign={'center'}>
									<SocialBtn href={theme.layoutsContent.webFooter.content.social.twitter}>
										<i className="fab fa-twitter" />
									</SocialBtn>
									<SocialBtn href={theme.layoutsContent.webFooter.content.social.facebook}>
										<i className="fab fa-facebook" />
									</SocialBtn>
									<SocialBtn href={theme.layoutsContent.webFooter.content.social.instagram}>
										<i className="fab fa-instagram" />
									</SocialBtn>
								</Flex>
							</Flex>
							<Link
								href={theme.layoutsContent.webFooter.content.social.appstore.link}
								maxWidth={'145px'}
							>
								<Image
									src={theme.layoutsContent.webFooter.content.social.appstore.image}
									maxWidth={'145px'}
									width={'100%'}
								/>
							</Link>
							<Link
								href={theme.layoutsContent.webFooter.content.social.googlePlay.link}
								maxWidth={'145px'}
							>
								<Image
									src={theme.layoutsContent.webFooter.content.social.googlePlay.image}
									maxWidth={'145px'}
									width={'100%'}
								/>
							</Link>
						</Flex>
					</Flex>
				</Container>
				<Container paddingBottom="1em">
					<Flex
						row
						width="100%"
						alignItems={'center'}
						justifyContent={'center'}
						flexDirection={'column'}
					>
						<Flex column>
							<Paragraph
								fontSize={'13px'}
								lineHeight={'1.1em'}
								textAlign={'center'}
								dangerouslySetInnerHTML={{
									__html: theme.layoutsContent.webFooter.content.copyright,
								}}
							/>
						</Flex>
					</Flex>
				</Container>
			</div>
		)
	}
}
export default HomeFooter
