import styled from 'styled-components'

export const ScrollableMask = styled.div`
	overflow: hidden;
	max-width: 100%;
	width: 100%;
`

export const ScrollableContent = styled.div`
	display: flex;
	flex-direction: row;
	transform: translateX(
		${props =>
			props.position > 0 ? props.position * -100 : props.position}vw
	);
	transition: all 0.4s;
	width: ${props =>
		props.totalPositions ? props.totalPositions * 100 + 'vw' : '400vw'};
`
