import styled from 'styled-components'

import theme from '../../../theme/content'

export const Group = styled.div`
	border: 1px solid ${theme.colors.dustGray};
	border-radius: 5px;
	padding: 0.3em 1em;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'unset')};
	width: 100%;
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1em;
	width: 100%;
`
export const ErrorMessage = styled.p`
	color: red;
	font-family: ${theme.fonts.primary};
	font-weight: bold;
`