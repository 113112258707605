import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"

import {
	Flex,
	Paragraph
} from 'reakit'

import {
	STAGING
} from '../../../factory/types'

import CustomInput from './CustomInput'

import {
	getDonations,
	getStatistics,
	setDateRange,
	refreshToken
} from '../../../factory'

import theme from '../../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			endDate: STAGING() ? moment("2019-03-31T00:00:00") : moment(),
			startDate: STAGING() ? moment("2018-08-01T00:00:00") : moment().subtract(15, 'days')
		}

		this.getStats = this.getStats.bind(this)
		this.getDonations = this.getDonations.bind(this)
		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeStart = this.handleChangeStart.bind(this)
		this.handleChangeEnd = this.handleChangeEnd.bind(this)
	}

	componentDidMount() {
		const {startDate, endDate} = this.state
		if (!(window.location.href.includes('projectId') ||
		 		window.location.href.includes('donors'))) {
			this.props.refreshToken()
				.then(()=> {
					this.getStats({startDate, endDate})
					this.getDonations()
				})
				.catch((err) => console.log(err))
		}
		this.getBodyWidth()

		window.addEventListener('resize', this.getBodyWidth.bind(this))
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}

  handleChange = ({ startDate, endDate }) => {
  	const realStartDate = startDate || this.state.startDate // eslint-disable-line
  	let realEndDate = endDate || this.state.endDate // eslint-disable-line
  	realEndDate.set({hour: 23, minute: 59, second: 59})
  	console.log(realEndDate)
  	if (realStartDate.isAfter(realEndDate)) {
  		realEndDate = realStartDate // eslint-disable-line
	  }


  	this.setState({ startDate: realStartDate, endDate: realEndDate })
  	if (!(window.location.href.includes('projectId') ||
  		 		window.location.href.includes('donors'))) {
  		this.getStats({ startDate: realStartDate, endDate: realEndDate })
  	}
  	this.props.onChangeDate({start_date: realStartDate, end_date: realEndDate})

  	this.props.onChange()
  }

  handleChangeStart = (startDate) => this.handleChange({ startDate })

  handleChangeEnd = (endDate) => this.handleChange({ endDate })

  getBodyWidth() {
  	this.setState({
  		bodySize: (window.innerWidth - 280)
  	})
  }

  getStats({startDate, endDate}) {
  	const date_from = startDate.format('MM-DD-YY') + " 00:00:00"
  	const date_to = endDate.format('MM-DD-YY') + " 23:59:59"

  	if (this.props.organization) {
  		const statsParams = {
	  		organization_id: this.props.organization.id,
	  		date_from,
	  		date_to,
	  		period: 1
	  	}

	  	this.props.onGetStatistics(statsParams, this.props.token)
  	}
  }

  getDonations() {
  	if (this.props.organization) {
  		this.props.onGetDonations(this.props.organization.id, this.props.token)
  	}
  }

  render() {
  	return (
  		<Flex row>
  			<Flex row marginRight={'2em'} backgroundColor={this.props.backgroundColor}>
  				<Flex column placeContent="center" height="100%" marginRight="0.5em">
  					<Paragraph style={{color: this.props.textColor, verticalAlign: 'center'}}> Start Date: </Paragraph >
  				</Flex>
  				<DatePicker
  					selected={this.state.startDate}
  					selectsStart
  					startDate={this.state.startDate}
  					endDate={this.state.endDate}
  					onChange={this.handleChangeStart}
  					dateFormat="MM-DD-YY"
  					customInput={<CustomInput/>}
  				/>
  			</Flex>
  			<Flex row marginRight={'2em'} backgroundColor={this.props.backgroundColor}>
  				<Flex column placeContent="center" height="100%" marginRight="0.5em">
  					<Paragraph style={{color: this.props.textColor, verticalAlign: 'center'}}> End Date: </Paragraph >
  				</Flex>
  				<DatePicker
  					selected={this.state.endDate}
  					selectsEnd
  					startDate={this.state.startDate}
  					endDate={this.state.endDate}
  					onChange={this.handleChangeEnd}
  					dateFormat="MM-DD-YY"
  					customInput={<CustomInput disabled={this.props.disabled}/>}
  				/>
  			</Flex>
  		</Flex>
  	)
  }
}

IndexPage.propTypes = {
	onGetDonations: PropTypes.func,
	onGetStatistics: PropTypes.func,
	organization: PropTypes.object,
	donations: PropTypes.array,
	projects: PropTypes.array,
	stats: PropTypes.object,
	token: PropTypes.string,
	onChangeDate: PropTypes.func,
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,
	onChange: PropTypes.func,
	refreshToken: PropTypes.func,
	disabled: PropTypes.bool
}

IndexPage.defaultProps = {
	onChange: ()=>{},
	backgroundColor: theme.colors.mineShaft,
	textColor: 'white'
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		donations: state.adminState.donations,
		projects: state.donateNowState.projects,
		stats: state.statistics,
		token: state.authState.accessToken,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetDonations: (orgId, token) => dispatch(getDonations(orgId, token)),
		onGetStatistics: (params, token) => dispatch(getStatistics(params, token)),
		onChangeDate: (dates) => dispatch(setDateRange(dates)),
		refreshToken: () => dispatch(refreshToken())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
