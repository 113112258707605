import styled from 'styled-components'

import {Link as Anchor} from 'reakit'

import theme from '../../../theme/content'

export const Link = styled(Anchor)`
	color: ${theme.colors.crusta};
	cursor: pointer;
`
