import styled from 'styled-components'

import theme from '../../../theme/content'

const Link = styled.a`
    display: inline-block;
    cursor: pointer;
    color: ${props => (props.color ? props.color : theme.colors.black)};
    text-decoration: none;
    margin-left: ${props => (props.marginLeft ? props.marginLeft : 'auto')};
    margin-right: ${props => (props.marginRight ? props.marginRight : 'auto')};
    max-width: ${props => (props.maxWidth ? props.maxWidth : 'unset')};
    width: 100%;

    &:hover{
      color: ${theme.colors.link};
      transition: all 0.3s;
    }

    &:visited{

    }
`

export default Link
