import React, {Component} from 'react'
import {StripeProvider} from 'react-stripe-elements'
import PropTypes from 'prop-types'
import {STRIPEKEY} from '../../../factory/types'
import MyStoreCheckout from './Checkout'
import { Overlay, Backdrop } from "reakit"


class Stripe extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
  		<Overlay.Container style={{overflow: 'scroll', maxHeight: 250}}>
  			{() => (
  				<div style={{width: '100vw', maxHeight: '80vh',  position: 'absolute', zIndex: 99}}>
  					<Backdrop visible={this.props.paid}/>
  					<Overlay visible={this.props.paid} style={{width: 400}}>
  						<StripeProvider apiKey={STRIPEKEY}>
  							<MyStoreCheckout />
  						</StripeProvider>
  					</Overlay>
  				</div>
  			)}
  		</Overlay.Container>
  	)
	}
}

Stripe.propTypes = {
	paid: PropTypes.bool
}
export default Stripe
