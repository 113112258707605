import styled from 'styled-components';

import theme from '../../../theme/content';

const SocialBtn = styled.a`
	background: ${theme.colors.mineShaft};
	border-radius: 33px;
	color: ${theme.colors.white};
	cursor: pointer;
	display: inline-block;
	height: 33px;
	line-height: 33px;
	transition: all 0.3s;
	text-align: center;
	width: 33px;

	&:not(:last-child) {
		margin-right: 1em;
	}

	&:hover,
	&:focus {
		background-color: ${theme.colors.sunglow};
		color: ${theme.colors.white};
	}
`;

export default SocialBtn;
