import React from 'react'


import {
	Backdrop,
	Portal,
	Block
} from 'reakit'

import {Button, Overlay} from './modalStyles'

const Modal = (props) =>(
	<Overlay.Container>
		{overlay => (
			<Block style={{...props.style}}>
				<Button as={Overlay.Show} {...overlay} {...props.button} onClick={props.onOpen}>{props.button.text}</Button>
				<Backdrop as={[Portal, Overlay.Hide]} {...overlay} />
				<Overlay style={{...props.containterStyle}} as={Portal} {...overlay}>{props.children}</Overlay>
			</Block>
		)}
	</Overlay.Container>
)

Modal.defaultProps = {
	onOpen: () => {}
}

export default Modal
