import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import './customStyles.css'

import theme from '../../../theme/content'

const headerStyle = {
	color: theme.colors.white,
	height: '2em',
	fontFamily: theme.fonts.secondary,
	fontSize: '1em',
	backgroundColor: theme.colors.crusta,
	fontWeight: '500',
}

class DynamicTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<ReactTable
				data={this.props.data}
				columns={this.props.columns.map(c => {
					c.headerStyle = headerStyle
					return c
				})}
				loading={false}
				minRows={3}
				defaultPageSize={this.props.maxRows || 10}
				noDataText="We don't have any information yet. No sweat, this happens."
				loadingText=""
				className="-striped -highlight"
				showPagination={this.props.paginate || false}
				style={{
					maxWidth: '100%',
					fontFamily: theme.fonts.primary,
					display: 'flex',
					...this.props.style
				}}
				defaultSorted={this.props.sortBy}
				getTrProps={(state, rowInfo) => {
					  if (rowInfo && rowInfo.row && this.props.onPress) {
					    return {
					      onClick: () => {
					        this.props.onPress(rowInfo.row._original)
					      },
							style: { cursor: 'pointer'}
					    }
					  }
					  return {}
				}}
			/>
		)
	}
}

// getTrProps={(state, rowInfo) => {
//   return {
//     style: {
//       textAlign: 'right'
//     }
//   };
// }}

DynamicTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	style: PropTypes.object,
	sortBy: PropTypes.array,
	onPress: PropTypes.func,
	paginate: PropTypes.bool,
	maxRows: PropTypes.number,
	loading: PropTypes.bool
}

export default DynamicTable
