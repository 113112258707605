import styled from 'styled-components'

import theme from '../../../theme/content'

import {responsiveFontSize} from '../../../util'

const Image = styled.img`
	align-self: center;
	height: ${props => (props.height ? props.height : 'auto')};
	margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1em'};
	margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
	margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
	margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
	max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
	width: ${props => (props.width ? props.width : '100%')};
`

export default Image
