import React, { Component } from 'react'
import Switch from "react-switch"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Form, Modal } from '../../layouts'

import {
	Card,
} from '../'

import {
	updateMessage, sendTest
} from '../../../factory'

import theme from '../../../theme/content'


class CustomMessage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			text: '',
			byline: '',
			is_active: 0,
			error: false,
			validationError: '',
			edited: false,
			email: '',
			phone: ''
		}

		this.renderForm = this.renderForm.bind(this)
		this.setValues = this.setValues.bind(this)
		this.submitUpdate = this.submitUpdate.bind(this)
		this.toggleActive = this.toggleActive.bind(this)
		this.errorUpdating = this.errorUpdating.bind(this)
		this.validate = this.validate.bind(this)
		this.successUpdate = this.successUpdate.bind(this)
	}


	componentDidMount() {
		this.getMessage()
	}

	getMessage() {
		const {messages = [], id} = this.props

		const message = messages && messages.filter(m => m.system_notification_type === id)
		let newByline = ''
		let m = {text: ''}
		if (message.length === 1) {
			m = message[0]
			newByline = m.byline.replace(' <br/> ', `\n`)
		}

		this.setState({
			...m,
			byline: newByline,
			organization_id: this.props.organization.id,
			system_notification_type: this.props.id
		})
	}

	setValues(e) {
		const name = e.target.name
		let value = e.target.value
		const test = e.target.name.includes('phone') ||  e.target.name.includes('email')

		if (name.includes('phone')) {
			value = value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
		}

		this.setState({
			[name]: value,
			edited: test ? false : true
		})
	}

	errorUpdating() {
		this.setState({error: true})
	}

	successUpdate() {
		this.setState({error: false})
	}

	submitUpdate() {
		let Byline = this.state.byline
		Byline = Byline.replace(/\n/, ' <br/> ')

		if (this.validate()) {
			this.setState({error: false, edited: false},
				this.props.updateMessage({...this.state, byline: Byline}, this.props.token, this.successUpdate, this.errorUpdating)
			)
		}
	}

	validate() {
		const {text, byline} = this.state
		if (text.length === 0 || (this.props.isEmail && byline.length === 0)) {
			this.setState({validationError: 'Message not saved. Please fill in all pieces of the form.'})
			return false
		}
		this.setState({validationError: ''})

		return true
	}

	toggleActive(e) {
		if (this.validate()) {
			this.setState({is_active: e}, this.submitUpdate)
		}
	}

	sendTest() {
		const newState = Object.assign({}, this.state)
		newState.testSuccess = ''

		if (this.state.system_notification_type === theme.customMessages.welcomeText) {
			newState.email = ''
		}
		this.setState(newState,
			this.props.sendTest(this.state, this.props.token, () => this.setState({testSuccess: 'Your message was successfully sent.'}), ()=>{})
		)
	}

	renderForm() {
		const {isEmail} = this.props

		if (isEmail) {
			return (
				<Form
					fields={
						[[
							{
								name: 'text',
								placeholder: `The money you give through Flourish will help us do so many exciting and new things! We can... You are the reason we are able to make this lasting impact. I cannot thank you enough for selecting the ${this.props.organization && this.props.organization.title} as your cause on Flourish.`,
								field: 'textarea',
								columns: '40',
								rows: '7',
								type: 'text',
								maxWidth: '100%',
								length: this.state.text.length,
								maxLength: 350,
								style: {
									minHeight: '8em',
								},
							},
						], [
							{
								name: 'byline',
								placeholder: `${this.props.userName} \n${this.props.organization && this.props.organization.title}`,
								field: 'textarea',
								columns: '40',
								rows: '2',
								type: 'text',
								maxWidth: '50%',
								length: this.state.byline.length,
								maxLength: 100,
								style: {
									minHeight: '3em',
								},
							}
						]]
					}
					setValues={this.setValues}
					submitForm={this.submitUpdate}
					submitBtnText={'Save'}
					values={this.state}
					defaultValues={this.state}
					logging={this.props.pending}
					error={this.state.validationError || (this.state.error ? this.props.error : '')}
				/>
			)
		}

		return (
			<Form
				fields={
					[[
						{
							name: 'text',
							placeholder: `${this.props.organization && this.props.organization.title} thinks you’re a rockstar! Thank you so much for rounding up to support our cause. You make the difference!`,
							field: 'textarea',
							columns: '40',
							rows: '4',
							type: 'text',
							maxWidth: '100%',
							length: this.state.text.length,
							maxLength: 120,
							style: {
								minHeight: '5em',
							},
						},
					]]
				}
				setValues={this.setValues}
				submitForm={this.submitUpdate}
				submitBtnText={'Save'}
				values={this.state}
				defaultValues={this.state}
				logging={this.props.pending}
				error={this.state.validationError || (this.state.error ? this.props.error : '')}
			/>
		)
	}

	render() {
		const {isEmail} = this.props
		const {testSuccess, edited} = this.state

		let testTitle = 'Please enter a phone number'
		if (isEmail) {
			testTitle = 'Please enter an email address'
		}

		return(
			<Card padding="1em">
				<Flex row justifyContent="space-between" width="100%" marginBottom="1em">
					<Heading as="h3" style={{flex: 5}}>{this.props.title}</Heading>
					<Modal
						style={{flex: 5}}
						containterStyle={{display: 'flex', flexDirection: 'column'}}
						button={{text: 'Send Test', width: '10em'}}
					>
						<Heading as="h3" style={{width: '100%'}}>{testTitle}</Heading>
						<Paragraph>{testSuccess}</Paragraph>
						{edited && <Paragraph style={{color: theme.colors.crusta}}>Please save your message before sending a test or your edits will not be included.</Paragraph>}
						{isEmail ?
							<Form
								fields={
									[[
										{
											label: 'Email',
											name: 'email',
											field: 'default',
											placeholder: 'hello@flourishchange.com',
											type: 'text',
											maxWidth: '400px',
										},
									]]
								}
								setValues={this.setValues}
								submitForm={()=>this.sendTest()}
								submitBtnText={'Send Email Test'}
								defaultValues={this.state}
								disabled={!/\S+@\S+\.\S+/.test(this.state.email)}
								error={this.state.error ? this.props.error  : ''}
							/> :
							<Form
								fields={
									[[
										{
											label: 'Phone number',
											name: 'phone',
											field: 'default',
											placeholder: '214-555-5555',
											type: 'text',
											maxWidth: '400px',
											maxLength: 10
										},
									]]
								}
								setValues={this.setValues}
								submitForm={()=>this.sendTest()}
								submitBtnText={'Send Text'}
								values={this.state}
								defaultValues={this.state}
								error={this.state.error ? this.props.error : ''}
							/>
						}
					</Modal>
					<Flex row style={{flex: 1}}>
						<Switch
							onChange={this.toggleActive}
							checked={!!this.state.is_active}
							onColor={theme.colors.sunglow}
							onColor={theme.colors.sunglow}
							onHandleColor={theme.colors.crusta}
							handleDiameter={35}
							uncheckedIcon={false}
							checkedIcon={false}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={75}
							name="sms"
							className="react-switch"
							id="material-switch"
						/>
						<Paragraph style={{lineHeight: '35px', marginLeft: 5, marginRight: 25}}>Active</Paragraph>
					</Flex>
				</Flex>
				<Flex margin="0 0 1em 0" column>
					{this.renderForm()}
				</Flex>
			</Card>
		)
	}
}

CustomMessage.propTypes = {
	organization: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	error: PropTypes.string,
	userName: PropTypes.string,
	type: PropTypes.string,
	isEmail: PropTypes.bool,
	title: PropTypes.string,
	message: PropTypes.string,
	messages: PropTypes.array,
	id: PropTypes.number,
	updateMessage: PropTypes.func,
	sendTest: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		token: state.authState.accessToken,
		pending: state.oneClickState.pending,
		userName: state.authState.userName,
		error: state.oneClickState.error,
		oneClick: state.oneClickState
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateMessage: (data, token, successCallBack, errorCallBack) => dispatch(updateMessage(data, token, successCallBack, errorCallBack)),
		sendTest: (data, token, successCallBack, errorCallBack) => dispatch(sendTest(data, token, successCallBack, errorCallBack))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomMessage)