import React from 'react'
import PropTypes from 'prop-types'

import {ScrollableMask, ScrollableContent} from './scrollableContainerStyles'

class ScrollableContainer extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<ScrollableMask>
				<ScrollableContent
					position={this.props.position}
					totalPositions={this.props.totalPositions}
				>
					{this.props.children}
				</ScrollableContent>
			</ScrollableMask>
		)
	}
}

ScrollableContainer.propTypes = {
	children: PropTypes.any,
	position: PropTypes.number,
	totalPositions: PropTypes.number,
}

export default ScrollableContainer
