import React from 'react'
import PropTypes from 'prop-types'

import {Heading} from 'reakit'

import {Label, Text} from './footerBlockStyles'

const FooterBlock = props => {
	return (
		<div>
			<Heading fontSize="24px">{props.data.title}</Heading>
			<Label>{props.data.email.label}</Label>
			<Text dangerouslySetInnerHTML={{__html: props.data.email.text}} />
			<Label>{props.data.phone.label}</Label>
			<Text dangerouslySetInnerHTML={{__html: props.data.phone.text}} />
			<Label>{props.data.address.label}</Label>
			<Text dangerouslySetInnerHTML={{__html: props.data.address.text}} />
		</div>
	)
}

FooterBlock.propTypes = {
	data: PropTypes.object,
}

export default FooterBlock
